import React from "react";

import Layout from "../../../components/Layout/Layout";

const Article25062021 = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">FORUM INFO SECURITY 2021</h1>
            <p className="bold">
              Wednesday, July 07, 2021 | 10:00| Grand Elysee
            </p>
            <p className="bold">Dear Partner! </p>
            <p>
              S&T Mold in collaboration with Cisco Systems and with the support
              of Elcore Group Distribution invites you to take part in S&T Mold
              FORUM INFO SECURITY 2021, which will take place on July 7, 2021 in
              the{" "}
              <a href="https://www.facebook.com/grand.elysee">Grand Elysee</a>{" "}
              hall festival.
            </p>
            <p className="bold">Agenda </p>
            <p className="bullet">
              10:00 -10:40 Transfer from{" "}
              <a href="https://ztower.md/ro/location/">Z Tower</a> (office S&T
              Mold){" "}
              <a href="https://www.facebook.com/grand.elysee">Grand Elysee</a>{" "}
            </p>
            <p className="bullet">10:40 –11:00 Registration & Welcome </p>
            <p className="bullet">11:00 –15:00 Presentations & Lunch </p>
            <p className="bullet">
              15:00 –18:00 Entertainment program: transfer to Asconi Winery,
              guide tour & Eno gastronomical dinner{" "}
            </p>
            <h1 className="title">Register Now</h1>{" "}
            <p>
              <a href="https://forms.office.com/r/iDCkuwi4Dy">
                {" "}
                REGISTER FORM LINK
              </a>
            </p>
            <h1 className="title">Seminar details: </h1>
            <p>Date:    07 iulie 2021 </p>
            <p>Address: Grand Elysee, Chișinăului 45 Str, Sîngera </p>
            <p>Time: 11:00 </p>
            <p>Transfer: Office S&T Mold (Z Tower) to Grand Elysee </p>
            <p>Time: 10:00 </p>
            <p className="bold">Speakers: </p>
            <p>Dmitry Olhovsky, ES manager, S&T Mold </p>
            <p>
              Andrei Skvorcevsky, Consulting Systems Engineer, Cisco System,
              Belorussia{" "}
            </p>
            <p>Andrei Kulikov, Pre-sale Cisco, DC, R&S, Elko.by, Belorussia </p>
            <p>Language: RU</p>
            <p>
              Please register using this form or calling S&T Marketing
              Department +373 60947047
            </p>
            <p>
              <a href="https://forms.office.com/r/iDCkuwi4Dy">
                {" "}
                REGISTER FORM LINK
              </a>
            </p>
            <p className="bold">We look forward to your participation! </p>
            <p className="bold">Your S&T Mold team </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Article25062021;
